<template>
   <v-container>
      <crud
         ref="crud"
         api-path="product"
         title="Product"
         :field="field"
         key-id="id"
      >
         <template v-slot:add_btn>
            <v-btn icon small color="indigo" title="buat produk baru" @click="detail_id='new'"><v-icon>mdi-plus</v-icon></v-btn>
         </template>
         <template v-slot:row_act="row">
            <v-btn icon small color="indigo" title="detail" @click="detail_id=row.product_id"><v-icon>mdi-dots-horizontal</v-icon></v-btn>
         </template>
      </crud>
      <product-fm :product_id="detail_id" v-on:close="closeFm" />
   </v-container>
</template>

<script>
import crud from '@/components/crud';
import ProductFm from '@/components/ProductFm.vue';

export default {
   components: { crud, ProductFm, },
   data() {
      return {
         field: [
            { value: 'product_id', label: '#', ro: true, tbl: true },
            { value: 'product_name', label: 'Nama', tbl: true, searchKey: true },
            { value: 'product_sku_moka', label: 'SKU Moka', tbl: true, searchKey: true },
            { value: 'product_sku_tokopedia', label: 'Tokopedia', tbl: true },
            { value: 'product_sku_shopee', label: 'Shopee', tbl: true },
            { value: 'product_sku_blibli', label: 'Blibli', tbl: true },
            { value: 'category_id', label: 'Kategori', tbl: true, filter: true, master: ['category','category_id','category_name'] },
            { value: 'product_active_status', label: 'Status', tbl: true, filter: true, master: ['product_active_status_name', 'status_id','status_name','list/verification_status']},
            { value: 'act', label: 'Aksi', ro: true, tbl: true, dis_sort: true }
         ],
         detail_id: null,
         load_ev: false,
         events: [],
         search_ev: null
      };
   },
   watch: {
      search_ev(val) {
         clearTimeout(this.event_timer);
         this.event_timer = setTimeout(() => {
            this.load_event = true;
            this.$api.crud.get('event', {event_name: val}).then(rsl => {
               this.events = rsl.data.map(x => ({value: x.event_id, text: x.event_name}));
            }).finally(() => { this.load_event = false });
         }, 1000);
      }
   },
   methods: {
      filterEvent(val) {
         this.field[2].filter_value = val;
         this.$refs.crud.getData();
      },
      closeFm(reload) {
         console.log('closefm', reload);
         this.detail_id = null;
         if(typeof reload === 'boolean') this.$refs.crud.getData();
      }
   }
}
</script>
