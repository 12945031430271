<template>
   <v-dialog :value="!!product_id" v-on:input="close" max-width="540" >
      <v-card>
         <v-card-title>
            {{title}} Product
            <v-spacer/>
            <v-icon color="red" @click="close">mdi-close</v-icon>
         </v-card-title>
         <v-divider class="mx-4 mb-4"></v-divider>
         <v-card-text  v-if="edit">
            <v-text-field v-model="fm.product_name" label="Nama" hide-details />
            <v-text-field v-model="fm.product_sku_moka" label="SKU Moka" hide-details />
            <v-text-field v-model="fm.product_sku_tokopedia" label="SKU Tokopedia" hide-details />
            <v-text-field v-model="fm.product_sku_shopee" label="SKU Shopee" hide-details />
            <v-text-field v-model="fm.product_sku_blibli" label="SKU Blibli" hide-details />
            <v-select v-model="fm.category_id" label="Kategory" :items="categorys" />
            <!-- <v-switch v-if="product_id!=='new'" v-model="fm.disabled" :true-value="1" :false-value="0" label="Disable" /> -->
         </v-card-text>
         <v-card-text v-else>
            <div class="list-line">
               <label class="grey--text">ID</label>
               <div>{{fm.product_id}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Nama</label>
               <div>{{fm.product_name}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">SKU Moka</label>
               <div>{{fm.product_sku_moka}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">SKU Tokopedia</label>
               <div>{{fm.product_sku_tokopedia}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">SKU Shopee</label>
               <div>{{fm.product_sku_shopee}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">SKU Blibli</label>
               <div>{{fm.product_sku_blibli}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Kategori</label>
               <div>{{fm.category && fm.category.category_name}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Status</label>
               <div :class="`${fm.product_active_status===1?'blue':'red'}--text`">{{fm.product_active_status_name}}</div>
            </div>
            <div class="list-line">
               <label class="grey--text">Dibuat</label>
               <div  class="text-right">
                  {{fm.product_created_by && fm.product_created_by.name || '-'}}
                  <div class="caption" v-dater="fm.product_created_datetime" />
               </div>
            </div>
            <div class="list-line">
               <label class="grey--text">Diupdate</label>
               <div  class="text-right">
                  {{fm.product_updated_by && fm.product_updated_by.name || '-'}}
                  <div class="caption" v-dater="fm.product_updated_datetime" />
               </div>
            </div>
         </v-card-text>
         <v-card-actions class="justify-center">
            <v-btn v-if="edit" width="220" color="primary" outlined rounded @click="save">save</v-btn>
            <dir v-else>
               <v-btn class="mr-2" color="indigo" outlined @click="edit=true;title='Edit'"><v-icon>mdi-pencil</v-icon>edit</v-btn>
               <v-btn v-if="fm.product_active_status===0" class="mr-auto" color="green" outlined @click="activate"><v-icon>mdi-check</v-icon>activate</v-btn>
               <v-btn v-else class="mr-auto" color="red" outlined @click="activate"><v-icon>mdi-cancel</v-icon>inactivate</v-btn>
            </dir>
         </v-card-actions>
      </v-card>
   </v-dialog>
</template>
<script>
import api from '../api';
import CFG from '../config';
export default {
   props: ['product_id'],
   data: () => ({
      title: 'Detail',
      fm: {},
      product_image: null,
      edit: false,
      load_data: false,
      load_save: false,
      categorys: [],
      load_category: false,
      img_url: CFG.img_url,
   }),
   watch: {
      product_id(val) {
         console.log('ch pro', val);
         if(val) {
            if(val === 'new') {
               this.title = 'Buat';
               this.edit = true;
            } else {
               this.title = 'Detail';
               this.edit = false;
               api.get(`product/${val}`).then(rsl => {
                  if(rsl.data) this.fm = rsl.data;
               });
            }
         }
      }
   },
   mounted() {
      console.log('det pro', this.product_id);
      this.load_category = true;
      api.get(`category`).then(rsl => {
         if(rsl.data) this.categorys = rsl.data.map(x => ({text: x.category_name, value: x.category_id}));
      }).finally(() => { this.load_category = false });
   },
   methods: {
      close(reload) {
         this.$emit('close', reload);
         this.fm = {};
      },
      activate() {
         const val = this.fm.product_active_status === 1;
         this.$alert({ text: `${val?'Disable':'Enable'} this data ?`, type: 'warning',
            ok: () => {
               api.edit('product',`${this.product_id}/${val?'inactivate':'activate'}`).then(rsl => {
                  if(rsl.error) {
                     this.$alert('Ooops!', rsl.error, 'error');
                  }
                  if(rsl.success) {
                     this.$alert({ title: 'Success', text: rsl.success, type: 'success', timer: 2000});
                     this.close(true);
                  }
               });
            },
            cancel: () => {}
         });
      },
      save() {
         const {id, ...data} = this.fm;
         (this.product_id === 'new' ? api.create('product', data) : api.edit('product', id, data)).then(rsl => {
            if(rsl.success) {
               this.$alert({title: 'Sukses', text: rsl.success, timer: 2000});
               this.close(true);
            } else this.$alert('Ooops!', rsl.error, 'error');
         });
      }
   }
}
</script>